<template>
  <b-card title="Listar Estados">
    <listarEstado />
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listarEstado from '../../Listagens/Estados/ListagemEstados.vue'

export default {
  components: {
    BCard,
    listarEstado,
  },
}
</script>
